import React from "react";
import ProgImg from "./ProgImg";
import "../Css/Card.css";
import Badge from "./Badge";
import { motion } from 'framer-motion'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export default function Card(props) {

    let { Level, Image, Title, Functions, Bonus } = props.data.attributes
    let cardClass = props.data.attributes.class

    let initial={ y: 50, opacity: 0, scale: .9}
    let animate={ y: 0, opacity: 1, scale: 1 }
   
    return (
            <motion.div 
                viewport={{ once: true }}
                initial={initial}
                whileInView={animate} 
                transition={{ delay: props.cardNumber * .1 }}
                className={`${cardClass}`}
                >
                <span className="level">{Level}</span>
                <div className="cardbody">
                    <figure>
                        <ProgImg src={SERVER_URL + Image.data.attributes.formats.medium.url} placeholderSrc={SERVER_URL + Image.data.attributes.formats.thumbnail} alt={`${Level} NFT card`} />
                    </figure>
                    <figcaption>
                        <Badge badgeClass={ Level.toLowerCase() } bonus={ Bonus }/>
                        <h4>{Title}</h4>
                        <div dangerouslySetInnerHTML={{__html: Functions}} />
                    </figcaption>
                </div>
            </motion.div>
            )
}