import React from "react";
import SimpleContent from "./SimpleContent";

const TheProject = (props) => {

    return ( 
        <>
        { props.data.map((article, i) => {
           return  <SimpleContent key={i} data={article} id={article.attributes.sectionid} />
        }) 
        }
        </>
    )

}

export default TheProject