import React, { useState } from "react";
import FaqPage from "../Components/FaqPage";
import Paginator from "../Components/Paginator";
import { motion } from "framer-motion";
import '../Css/FAQ.scss'

const FAQSection = (props) => {

    const [ currentPage, setCurrentPage ] = useState(1)

    let faqs = props.faqs.data
    let { maintitle, subtitle, sectionclass, perPage } = props.data.attributes
    let pcurr = 0
    let maxitems = faqs.length
    let counter = 0
    let liChildren = []
    let pages = 0

    const handlePage = (e, p) => {
        setCurrentPage(p)
    }

    const handleDrag = (e, i) => {
        if (Math.abs(i.offset.x) > (window.innerWidth/6)) {
            let cp = currentPage
            cp = cp + (-Math.sign(i.offset.x))
            if (cp < 1) cp = 1
            if (cp > pages) cp = pages
            handlePage(e, cp)
        }
    }

    return (
        <article className={`faqsection ${ sectionclass }`} id="FAQ">
            <h2>{maintitle}</h2>
            <h3>{subtitle}</h3>
            <div className="FAQItemsWrapper">
            <motion.div 
                    onDragEnd={handleDrag} 
                    drag="x"
                    dragDirectionLock
                    dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                    dragElastic={0.5}
                    whileTap={{ cursor: "grabbing" }}
                    className="FAQItemPages" style={{ width: `${Math.ceil(maxitems / perPage)  * 100}%` }}>
                {
                    faqs.map((item, index) => {
                        counter++
                        pcurr++
                        liChildren.push(item)
                        if (pcurr === perPage || counter === maxitems) {
                            pcurr = 0
                            let tmp = liChildren
                            liChildren = []
                            pages++
                            return ( <motion.div key={index} className="pagewrap" initial={{ x: 0 }} animate={{ x: `-${(currentPage - 1) * 100}%` }} ><FaqPage items={ tmp } /></motion.div> )
                        }
                        return null
                    })
                }
                </motion.div>
            </div>
            <Paginator pages={pages} currentPage={currentPage} handlePage={handlePage} />
        </article>
    )

}

export default FAQSection