import React from "react";
import HambButton from "./HambiButton";
import { motion } from "framer-motion";
import { Link } from 'react-scroll'
import '../Css/HambMenu.scss'
import logo from '../Img/PoziCLogoV_noca.svg'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const HambMenu = (props) => {

    const menuitems = props.data
    const liInitial = { x: 20, opacity: 0 }
    const liAnimate = { x: 0, opacity: 1 }

    return (
        <div className={"hmbmenuWrap " + props.className}>
            <HambButton handleMenu={props.handleMenu} className={ props.className === "menuopen" ? "opened" : "" } />
            <motion.div 
                className="nav hmenu"
                initial={{ x: "100vw", opacity: 0 }}
                animate={ props.className !== null ? { x: 0, opacity: 1 } : { x: "100vw", opacity: 0 } }
                >
                <img src={logo} alt="PoziCard Startup NFT" className="menulogo"/>
                <ul>
                    { menuitems.map((item, i) => {
                        return (
                            <motion.li 
                                key={i}
                                initial={ liInitial }
                                animate={ props.className !== null ? liAnimate : liInitial }
                                transition={{ delay: i * .075 }}
                                >
                                <motion.span whileHover={{ color: "rgb(255, 255, 255)" }}
                                            initial={{ color: "rgb(0, 198, 255)" }}>
                                <Link key={i} 
                                    className={ item.attributes.itemClass }
                                    to={ item.attributes.link }
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={300}
                                    onClick={props.handleMenu}>
                                    <img src={SERVER_URL + item.attributes.icon.data.attributes.url} alt={ item.attributes.caption } />
                                    { item.attributes.caption }
                                </Link>
                                </motion.span>
                            </motion.li>
                        )
                    })
                    }
                </ul>
            </motion.div>
        </div>
    )

}

export default HambMenu