import React from "react";
import { motion } from "framer-motion";
import "../Css/ProjectCard.scss"

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ProjectCard = (props) => {

    let  { title, subtitle, url, description, colorScheme } = props.data.attributes
    let pImage = props.data.attributes.Image.data.attributes.url

    return (

        <motion.div 
            className={`ProjectCard ${ colorScheme.toLowerCase() }`}
            viewport={{ once: true }}
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: props.animDelay * .1 }}
            >
            <figure style={{ backgroundImage: `url(${SERVER_URL + pImage})`, backgroundSize: 'cover' }}></figure>
            <div className="pcardtext">
                <h4>{title}</h4>
                <h5>{subtitle}</h5>
                <div className="desc" dangerouslySetInnerHTML={{ __html: description }} />
                <a href={ url } rel="noreferrer" target="_blank" className="button btn cyan arrow">
                    <svg viewBox="0 0 16 10"><g id="Layer_2"><g id="Magicons"><path className="cls1" d="M12.75,6,10.27,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.43,0l3.85-3.65A1.47,1.47,0,0,0,16,5a1.5,1.5,0,0,0-.44-1.06L11.71.29a1,1,0,0,0-1.44,0,1,1,0,0,0,0,1.42L12.75,4H1A1,1,0,0,0,1,6Z"/></g></g></svg>
                </a>
            </div>
        </motion.div>

        )

}

export default ProjectCard

