import React from "react";
import { Link  } from "react-scroll";
import { motion } from "framer-motion";
import PoziLogo from '../Img/PoziLogo.svg'
import '../Css/BannerSection.css'
import NavButton from "../Components/NavButton";

const BannerSection = (props) => {

    let atb = props.data.attributes
    let menuData = props.menuData
    let bannervideo = atb.BannerVideo.data
    let bannerimage = atb.BannerImage.data
    let mainLogo = atb.mainLogo.data
    let mobileLogo = atb.mobileLogo.data
    let { HeroText, HeroLinkText, HeroLinkURL, sectionclass } = props.data.attributes
    let counter = 0

    return (
        <article className={`section ${sectionclass}`} id="BannerSection">
            <div className="vidwrap">
                <video className="herovideo" preload="none" autoPlay muted loop >
                    <source src={process.env.REACT_APP_SERVER_URL + bannervideo.attributes.url} type="video/mp4" />
                    <img src={process.env.REACT_APP_SERVER_URL + bannerimage.attributes.url} alt="PoziCard" />
                </video>
            </div>
            <div className="heroText">
                <div className="Navmenu">
                    <img src={ PoziLogo } alt="Empowering the startup ecosystem" />
                    <ul className="nav">
                        { 
                            menuData.map((item, index) => {
                                if (item.attributes.ShowOnTopMenu)  counter++
                                return ( item.attributes.ShowOnTopMenu ? <motion.li key={index} initial={{x:-20, opacity:0}} animate={{x:0, opacity:1}} transition={{delay: counter * 0.3}}><Link key={index} to={item.attributes.link} activeClass="active" spy={true} smooth={true} offset={0} duration={300}>{item.attributes.caption}</Link></motion.li> : null )
                            })
                        }
                    </ul>
                </div>
                <div className="logoslogwrap">
                    <img className="heroLogo" src={process.env.REACT_APP_SERVER_URL + mainLogo.attributes.url} alt="PoziCard - The first NFT crowdfunding solution for startups!" />
                    <img className="heroLogoMobile" src={process.env.REACT_APP_SERVER_URL + mobileLogo.attributes.url} alt="PoziCard - The first NFT crowdfunding solution for startups!" />
                    <h1>{HeroText}</h1>
                </div>
                <NavButton navTo={HeroLinkURL} navText={HeroLinkText} className="btn button cyan" />
            </div>
        </article>
    )

}

export default BannerSection