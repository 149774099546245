import React from "react";
import { motion } from "framer-motion";
import '../Css/SimpleContent.scss'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const SimpleContent = (props) => {

    let { maintitle, subtitle, content, sectionclass } = props.data.attributes
    let id = props.id

    let initial={ y: 50, opacity: 0}
    let animate={ y: 0, opacity: 1}

    return (
        <motion.article
            viewport={{ once: true }}
            initial={ initial }
            whileInView={ animate }
            id={id} 
            className={sectionclass}>
            <h2 dangerouslySetInnerHTML={{__html: maintitle }} />
            <h3 dangerouslySetInnerHTML={{__html: subtitle }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {
                // eslint-disable-next-line
                'attachment' in props.data.attributes && props.data.attributes.attachment.data !== null ? <motion.div initial={{ y: 50, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} viewport={{ once: true }} className="attachment"><img src={SERVER_URL + props.data.attributes.attachment.data.attributes.url} alt="picture" /></motion.div> : null
            }
        </motion.article>
    )

}

SimpleContent.defaultProps = {
    id: "",
    css: "HowItWorks"
}

export default SimpleContent