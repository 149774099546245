import React from "react";
import { motion } from "framer-motion";
import '../Css/SimpleContent.scss'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const WhoWeAre = (props) => {

    let { maintitle, subtitle, content, sectionclass, content2, socials, staffmembers } = props.data.attributes
    let id = props.id
    let staff = staffmembers.data
    let socs = socials.data

    let initial={ y: 50, opacity: 0}
    let animate={ y: 0, opacity: 1}

    return (
        <motion.article
            viewport={{ once: true }}
            initial={ initial }
            whileInView={ animate }
            id={id} 
            className={sectionclass}>
            <h2 dangerouslySetInnerHTML={{__html: maintitle }} />
            <h3 dangerouslySetInnerHTML={{__html: subtitle }} />
            <div className="wwaWrap">
                <ul className="stafflist">
                    {
                        staff.map((s, i) => {
                            return (
                                <motion.li
                                    key={i}
                                    initial={{ y: 20, opacity: 0 }}
                                    whileInView={{ y:0, opacity: 1 }}
                                    transition={{ delay: i * 0.1 }}
                                    >
                                    <a href={s.attributes.profilelink} rel="noreferrer" target="_blank">
                                    <motion.img whileHover={{ scale: 1.05 }} src={SERVER_URL + s.attributes.image.data.attributes.url} alt={ s.attributes.name } />
                                    <strong>{s.attributes.name}</strong>
                                    <span>{s.attributes.title}</span>
                                    </a>
                                </motion.li>
                            )
                        })
                    }
                </ul>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <div className="row social">
                    <div className="col span8" dangerouslySetInnerHTML={{ __html: content2 }} />
                    <div className="col span4 clr">
                        <ul className="socials">
                            {
                                socs.map((soc, i) => {
                                    return(
                                        <motion.li whileHover={{ rotate: 5, scale: 1.1 }} key={i}><a href={soc.attributes.link} rel="noreferrer" target="_blank"><img src={SERVER_URL + soc.attributes.image.data.attributes.url} alt={ soc.attributes.name } /></a></motion.li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            {
                // eslint-disable-next-line
                'attachment' in props.data.attributes ? <motion.div initial={{ y: 50, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} viewport={{ once: true }} className="attachment"><img src={SERVER_URL + props.data.attributes.attachment.data.attributes.url} alt="picture" /></motion.div> : null
            }
        </motion.article>
    )

}

WhoWeAre.defaultProps = {
    id: "",
    css: "HowItWorks"
}

export default WhoWeAre