import React from "react";
import '../Css/Paginator.scss'

const Paginator = (props) => { 

    let { pages, currentPage, handlePage } = props

    return (
        <ul className="paginator">
            {
            [...Array(pages)].map((item, i) => <li key={i} className={ currentPage === i+1 ? "page current" : "page" }><button onClick={(e) => handlePage(e, i+1)}>{i+1}</button></li>)
            }
        </ul>
    )

}

export default Paginator