import React from "react";
import CntUp from "../Components/Cntup";
import '../Css/SimpleContent.scss'

const ReferencesSection = (props) => {

    const refcounters = props.data

    return (
        <article id="References" className="section references">
            <h2>References</h2>
            <div className="content">
            { refcounters.map((r, i) => {
                return (
                    <CntUp end={ r.attributes.Target } title={ r.attributes.Title } key={i} />
                )
            })
            }
            </div>
        </article>
    )

}

export default ReferencesSection