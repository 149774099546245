import React /*, { useState, useEffect }*/ from "react";
import "../Css/CardSection.css";
import Card from "../Components/Card";

const CardSection = (props) => {

    let cards = props.data.attributes.cards.data
    let { maintitle, subtitle, sectionclass, Contentlead, Contentbody } = props.data.attributes

    return (
        <article className={`section ${ sectionclass }`} id="CardSection">
            <h2 dangerouslySetInnerHTML={{__html: maintitle }} />
            <h3 dangerouslySetInnerHTML={{__html: subtitle }} />
            <div className="row">
                <div className="col span6 lead" dangerouslySetInnerHTML={{__html: Contentlead }} />
                <div className="col span6 clr" dangerouslySetInnerHTML={{__html: Contentbody }} />
            </div>
            <div className="cardsWrapper">
                { cards.map((card, index) => {
                    return  <Card data={card} key={index} cardNumber={index} />
                    })
                }
            </div>
        </article>
    )
}

export default CardSection