import React from "react";
import { Link } from "react-scroll"
import '../Css/NavButton.css'
import { motion } from 'framer-motion'

const NavButton = (props) => {

    let {navTo, offset, duration, navText} = props 

    return (
        <motion.div className={props.className} whileHover={{ 
            scale: 1.05,
            
            
            }}>
            <Link
                activeClass={props.className}
                to={navTo}
                spy={true}
                smooth={true}
                offset={offset}
                duration={duration}
                >
                {navText}
            </Link>
        </motion.div>
    )

}

NavButton.defaultProps = {
    activeClass: 'active',
    navTo: "CardSection",
    offset: 0,
    duration: 500,
    navText: "More"
}

export default NavButton