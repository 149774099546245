import React from "react";
import { Link } from "react-scroll";
import '../Css/FooterMenu.scss'
import Logo from '../Img/PoziCLogoH.svg'
import Rocket from '../Img/rocket.png'


const FooterMenus = (props) => {

    let cats = []

    props.data.forEach(menuelem => {
        let ctg = menuelem.attributes.category.data.attributes.name
        if ( !cats.includes(ctg) ) {
            cats.push(ctg)
        }
    })

    return (
        <article id="ftnavarea" className="section">
            <div className="row">
                <div className="col span4">
                    <div className="ftlogocntnr">
                        <img src={Logo} alt="PoziCard" />
                        <img src={Rocket} alt="Your Access to the Startup Universe" />
                    </div>
                </div>
                <div className="col span8 ftmenus">{
                    cats.map((cat, i) => {
                        let menulist = props.data.map((menuelem, i) => { 
                            if (menuelem.attributes.category.data.attributes.name === cat) {
                                return menuelem.attributes.url.includes('http') ? <li key={i}><a href={ menuelem.attributes.url } target="_blank" rel="noreferrer">{menuelem.attributes.title}</a></li> : <li key={i} ><Link activeClass="active" to={menuelem.attributes.url} smooth={true} offset={0} duration={300}>{menuelem.attributes.title}</Link></li>
                            }
                            return null
                            })
                        return <div className={`ftmenu ${cat.replace(/\s/g, '').toLowerCase()}`} key={i} ><h3>{cat}</h3><ul>{menulist}</ul></div>
                    })
                }</div>

            </div>
        </article>
    )

}

export default FooterMenus