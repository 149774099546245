import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const CntUp = (props) => {

    const [ durRnd, setDurationRandom ] = useState();
    const { ref: counterRef, inView: isInView } = useInView({ threshold: .1 })

    const handleStuff = () => {
        console.log("doin something")
    }

    useEffect(()=> {
        setDurationRandom(2 + Math.random()*4)
    }, [])

    return( 
        <div className="counter" ref={counterRef}>
            <svg viewBox="0 0 82.31 82.31">
                <defs>
                    <filter id="f2" x="-0.5" y="-1" width="400%" height="400%">
                    <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <motion.path viewport={{ once: false }} animate={{ pathLength: isInView ? 1 : 0, opacity: isInView ? 1 :0 } } initial={{ pathLength: 0, opacity: .5 }} transition={{ duration: isInView ? durRnd : 0 }} onViewportEnter={handleStuff} className="cls-1" d="M41.16,10.56a30.6,30.6,0,1,1-30.6,30.6,30.6,30.6,0,0,1,30.6-30.6" filter="url(#f2)" />
                        <rect className="cls-2" width="82.31" height="82.31"/>
                    </g>
                </g>              
            </svg>
            { 
                isInView ? <CountUp redraw={false} start={ 0 } end={props.end} duration={durRnd} suffix="+" useEasing={false} /> : null
            }
            <h3>{props.title}</h3>
        </div>
    )
}

CntUp.defaultProps = {
    end: 500,
    title: 'Counter'
}

export default CntUp