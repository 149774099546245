import React, { useRef, useEffect, useState } from "react";
import SimpleContent from "./SimpleContent";
import ProjectCard from "../Components/ProjectCard";
import { motion } from "framer-motion";
import '../Css/ActualProjects.scss'

const ActualProjects = (props) => {
    
    const [ width, setWidth ] = useState(0)
    const CarouselOuter = useRef()
    let { data, id } = props

    useEffect(() => {
        setWidth( CarouselOuter.current.scrollWidth - CarouselOuter.current.offsetWidth )
    },[])

    return (
        <>
            <SimpleContent data={data} id={id} />
            <article id="ProjectSlider" className="section">
                <motion.div className="projectCardsCarousel" ref={CarouselOuter}>
                    <motion.div className="projectCardsWrp"
                        drag="x"
                        dragConstraints={{ left: -width, right: 0 }}
                        dragDirectionLock
                        dragTransition={{ bounceStiffness: 100, bounceDamping: 20 }}
                        dragElastic={0.5}
                        whileTap={{ cursor: "grabbing" }}
                        >
                    {
                        data.attributes.projects.data.map((p, i)=>{
                            return <ProjectCard key={i} data={p} animDelay={i} />
                        })
                    }
                    </motion.div>
                </motion.div>
            </article>
        </>
    )

}

export default ActualProjects