import React from "react";
import { motion } from "framer-motion";

const pathMotion = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
        pathLength: 1,
        opacity: 1,
        transition: {
            repeat: Infinity,
            repeatType: "mirror",
            duration: 5
        }
    }
}

const Loader = () => {

    return (
        <div className="loader">
            <svg viewBox="0 0 1136.54 118.65">
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M130.68,45.77V33.39c0-7.37-4.79-17.12-13.26-17.12H16.29V118.1H.5V.55H117.42c16,0,29,14.82,29,32.89V45.77c0,18.08-13.07,32.9-29,32.9H47.05V62.9h70.37C125.89,62.9,130.68,53.15,130.68,45.77Z"/>
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M275,.55c16,0,29,14.81,29,32.89V85.26c0,18.08-13.07,32.89-29,32.89H179.3c-16,0-29-14.81-29-32.89V33.39C150.3,15.31,163.37.5,179.3.5H275Zm-32.1,101.83H275a10.46,10.46,0,0,0,3-.43c6.59-2,10.25-10.24,10.25-16.69V33.39c0-6.46-3.66-14.73-10.25-16.69a10.46,10.46,0,0,0-3-.43H179.3a10.46,10.46,0,0,0-3,.43C169.71,18.7,166,26.93,166,33.39V85.21c0,6.45,3.67,14.72,10.26,16.69a10.46,10.46,0,0,0,3,.43h63.64Z"/>
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M308.32,113.48v-.68c0-5.91-.27-14.87,2.46-20.08,2.37-4.53,7.4-7.55,11.4-9.89,9-5.28,18.32-10,27.32-15.28l85.26-49.23H308.32V.55H454.23V5.84c0,5.91.27,14.87-2.46,20.09-2.36,4.52-7.4,7.54-11.4,9.88-9,5.29-18.28,10-27.33,15.24l-85.26,49.24H454.23v17.86H308.32Z"/>
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M475.5.55v117.6H459.76V.55Z"/>
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M664.06,118.1H551.12c-16,0-29-14.81-29-32.89V33.39c0-18.08,13.07-32.89,29-32.89H664.06V16.26H551.12a10.47,10.47,0,0,0-3,.44c-6.59,2-10.25,10.23-10.25,16.69V85.21c0,6.45,3.66,14.72,10.25,16.69a10.46,10.46,0,0,0,3,.43H664.06Z"/>
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M687.5,118.1H667.91L742.84,16.91C750.5,6.55,760.62.91,772.5.55h56.16v117.6H812.93V16.26H772.84c-7.44.34-12.94,4.1-17.33,10Z"/>
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M1002,.55H1107c16,0,29,14.81,29,32.89V85.26c0,18.08-13.07,32.89-29,32.89H986.22V.55ZM1107,102.38a10.46,10.46,0,0,0,3-.43c6.59-2,10.25-10.24,10.25-16.69V33.39c0-6.46-3.66-14.73-10.25-16.69a10.47,10.47,0,0,0-3-.44H1002v86.07Z"/>
                <motion.path variants={pathMotion} initial="hidden" animate="visible" className="cls1" d="M964.66,45.77V33.39c0-7.37-4.79-17.12-13.26-17.12H850.27V118.1H834.5V.55H951.4c16,0,29,14.82,29,32.89V45.77c0,17.15-11.77,31.37-26.6,32.78l28.5,39.55H962.9L934.5,78.67H881V62.9H951.4C959.87,62.9,964.66,53.15,964.66,45.77Z"/>
            </svg>
        </div>
    )

}

export default Loader
