import React from "react";
import NitroLogo from "../Img/nitro.svg"
import '../Css/Badge.css'

const Badge = (props) => {

    let { badgeClass, bonus } = props

    return (
        <div className="triangleWrapper">
            <div className="triangleShadow"></div>
            <div className={`triangle ${badgeClass}`}>
                <img className="nitroLogo" src={ NitroLogo } alt="Nitro" />
                <p className="bonus">{ bonus }<br/><span>BONUS</span></p>
                <span className="badgeLevelIndicator i1"></span>
                <span className="badgeLevelIndicator i2"></span>
                <span className="badgeLevelIndicator i3"></span>
            </div>
        </div>
    )
}

Badge.defaultProps = {
    badgeClass: 'bronze',
    bonus: 0
}

export default Badge