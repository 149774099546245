import React from "react";
import { motion } from "framer-motion";

const RmPage = ({ items }) => {

    return (
        <div className="page">
            <ul>
                { 
                    items.map((item, index) => {
                        return (
                            <li key={index} className={ item.attributes.done ? "achieved" : "future" }>
                                <motion.span viewport={{ once: true }} initial={{ x: -20, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ delay: index * .1 }} className="rm_year">{ item.attributes.Year }<span className="rm_quarter"> { item.attributes.Quarter }</span></motion.span>
                                <span className="spacer"></span>
                                <motion.p viewport={{ once: true }} initial={{ x: 20, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ delay: index * .1 }} className="roadmapTitle">{ item.attributes.title }</motion.p>
                                <p className="roadmapDescription" dangerouslySetInnerHTML={{ __html: item.attributes.content }} />
                            </li>)
                    })
                }
            </ul>
        </div>
    )

}

export default RmPage