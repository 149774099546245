import React from "react";
import "../Css/MintSection.scss"
import rocket from '../Img/rocket.png'
import rocketIcon from '../Img/icon_rocket.svg'
import { motion } from 'framer-motion'

const MintSection = (props) => {

    let { maintitle, subtitle, sectionclass, Contentbody, ButtonText, ButtonLink } = props.data.attributes

    return (
        <article className={`section ${sectionclass}`} id="MintSection">
            <div className="row">
                <div className="col span6">
                    <div className="hwrap">
                        <h2 dangerouslySetInnerHTML={{__html: maintitle }} />
                        <h3 dangerouslySetInnerHTML={{__html: subtitle }} />
                    </div>
                    <img src={ rocket } alt="rocket" className="rocket" />
                </div>
                <div className="col span6 clr">
                    <motion.div className="btn button dark large imagebtn" whileHover={{ scale: 1.05 }}>
                        <a href={ButtonLink} target="_blank" rel="noreferrer"><span dangerouslySetInnerHTML={{__html: ButtonText }} /><img src={rocketIcon} alt={ButtonText} /></a>
                    </motion.div>
                    <div dangerouslySetInnerHTML={{__html: Contentbody }} />
                </div>
            </div>
        </article>
    )

}

export default MintSection