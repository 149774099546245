import React from 'react'
import PoziLogo from '../Img/PoziLogo.svg'
import { motion } from 'framer-motion'
import '../Css/FooterLegal.scss'

const FooterLegal = (props) => {

    return (

        <div className="ftlegal">
            <div className="ftlegalcontent">
                <motion.a whileHover={{ scale: 1.1 }} href="https://pozi.io" target="_blank" rel="noreferrer"><img src={PoziLogo} alt="Pozi.io" /></motion.a>
                <p dangerouslySetInnerHTML={{ __html: props.data.attributes.content}} />
            </div>
        </div>

    )

}

export default FooterLegal