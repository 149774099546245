import React, { useState } from "react"
import PlusSign from '../Img/plus.svg'
import { motion } from "framer-motion"

const FaqItem = ({ item }) => {

    const [ open, setOpen ] = useState(false)

    const handleState = () => {
        setOpen(!open)
    }

    return (
        <div className={`tabitem ${open ? "opened" : "closed"}`}>
            <motion.button onClick={handleState} initial={{ rotate: 0 }} animate={ open ? { rotate: 225} : {rotate: 0} }><img src={ PlusSign } alt="Toggle" /></motion.button>
            <p onClick={handleState} className="faqTitle">{ item.attributes.title }</p>
            <motion.div initial={{ maxHeight: 0 }} animate={ open ? { maxHeight: 1500 } : { maxHeight: 0 } } className="faqDescription" dangerouslySetInnerHTML={{ __html: item.attributes.content }} />
        </div>
    )

}

export default FaqItem