import React from "react";
import FaqItem from "./FaqItem";

const FaqPage = ({ items }) => {

    return (
        <div className="page">
            <ul>{ 
                items.map((item, index) => {
                    return (
                    <li key={item.id} className="tab">
                        <FaqItem key={item.id} item={item} />
                    </li>)
                    })
            }</ul>
        </div>
    )
}

export default FaqPage